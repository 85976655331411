<template>
  <BaseDialog :dialogVisible.sync="dialog" width="600px" :title="title">
    <template>
      <BaseForm ref="form" label-width="auto" :cols="formField" :form="form" v-loading="loading">
        <template #isDefaultSlot>
          <el-switch
            :active-value="PROTO_GROUP_IS_DEFAULT"
            :inactive-value="PROTO_GROUP_NOT_IS_DEFAULT"
            v-model="form.isDefault"
            active-color="#3841DB"
            inactive-color="#495060"
          ></el-switch>
        </template>
      </BaseForm>
    </template>
    <template #footer>
      <el-button :loading="loading" type="primary" @click="doSubmit"> 确认 </el-button>
      <LoadingBtn @click="cancel"> 取消 </LoadingBtn>
    </template>
  </BaseDialog>
</template>
<script>
import { protoBaseFormField as formField } from './field'
import { commonFromMixin } from '@/mixins'
import { enableProtoGroup } from '@/api/product/protoGroupApi'
import { PROTO_GROUP_ENABLE, PROTO_GROUP_NOT_IS_DEFAULT, PROTO_GROUP_IS_DEFAULT } from '@/utils/constant'

export default {
  mixins: [commonFromMixin],
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      formField,
      form: {
        isDefault: PROTO_GROUP_NOT_IS_DEFAULT
      },
      PROTO_GROUP_NOT_IS_DEFAULT,
      PROTO_GROUP_IS_DEFAULT,
      loading: false
    }
  },

  methods: {
    async doSubmit() {
      this.loading = true
      try {
        const { id } = this.data
        const { isDefault } = this.form
        const { code } = await enableProtoGroup({
          id,
          isDefault,
          enable: PROTO_GROUP_ENABLE
        })
        if ($SUC({ code })) {
          this.success()
        }
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
