<template>
  <Popover placement="top" @sureHandler="del(data.id, $event)" class="left">
    <template #tip>
      <p>确定要进行删除操作吗？</p>
    </template>
    <template #reference="{ scope: loading }">
      <color-text-btn :loading="loading" size="mini" type="danger">删除</color-text-btn>
    </template>
  </Popover>
</template>

<script>
import { delMixin } from '@/mixins'
import { del as delApi } from '@/api/imageTypeApi'

export default {
  mixins: [delMixin],

  props: {
    data: {
      type: Object,
      required: true
    },

    sup_this: {
      type: Object,
      required: true
    }
  },

  methods: {
    delApi,

    init() {
      this.sup_this && this.sup_this.init()
    }
  }
}
</script>
