<template>
  <div class="inline-block">
    <el-button v-if="!isEdit" :loading="loading" type="info" size="mini" class="filter-item" @click="to">
      {{ btnTxt }}
    </el-button>
    <color-text-btn v-else :loading="loading" @click="to">{{ btnTxt }}</color-text-btn>
    <DistributionDialog
      type="default"
      v-model="value"
      v-if="showDistributionDialog"
      :visible.sync="dialogVisible"
      :form="form"
      :isEdit="isEdit"
      :keepSelectionDataAfterSearch="true"
      :resetMergeData="resetMergeData"
      v-on="new$Listeners"
    />
  </div>
</template>
<script>
import DistributionDialog from '@/views/components/distributionDialog'
import { commonEheaderMixin } from '@/mixins'
import { addPrototypeUpdate, getProtoGroupDetail } from '@/api/product/protoGroupApi'
import { checkPermission } from '@/utils'
import { SHELVES } from '@/utils/constant'

export default {
  mixins: [commonEheaderMixin],
  components: { DistributionDialog },

  props: {
    data: {
      type: Object,
      default: () => ({})
    },

    isExtend: {
      type: Boolean,
      default: false
    },

    isEdit: Boolean
  },

  data() {
    return {
      loading: false,
      form: {},
      value: [],
      resetMergeData: {
        status: SHELVES,
        orderItems: [
          { asc: false, column: 'create_time' },
          { asc: true, column: 'id' }
        ]
      },
      showDistributionDialog: false
    }
  },

  computed: {
    btnTxt({ isExtend, isEdit }) {
      if (isExtend) return '添加'
      if (isEdit) return '编辑'
      return '添加原型分组'
    },

    new$Listeners({ $listeners, isExtend }) {
      if (!isExtend) return $listeners
      return Object.assign({}, $listeners, {
        submit: async (data, done, context) => {
          const { name, id } = context.form
          const res = await awaitResolveDetail(addPrototypeUpdate({
            id,
            name,
            prototypeIdList: data
          }))
          done([!res, res])
        }
      })
    }
  },

  watch: {
    dialogVisible(newVal) {
      if (newVal) return
      this.$nextTick(() => {
        this.value = []
        this.showDistributionDialog = false
      })
    }
  },

  methods: {
    async to() {
      this.form = {}
      this.showDistributionDialog = true
      if (this.isEdit && checkPermission(['externaladmin:protoGroup:editGroup:detail'])) {
        const [err, data] = await this.getProtoGroupDetail()
        if (err) return
        this.value = data.prototypeIdList || []
        this.$set(this.form, 'name', data.name)
        this.$set(this.form, 'id', data.id)
        console.log('this.form', this.form)
      }
      this.$nextTick(() => {
        this.dialogVisible = true
      })
    },

    async getProtoGroupDetail() {
      const { id } = this.data
      this.loading = true
      try {
        const { code, detail } = await getProtoGroupDetail({ id })
        return [!$SUC({ code }), detail || {}]
      } catch {
        return [true, {}]
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
