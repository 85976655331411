import request from '@/service/request'
import store from '@/store'
import { getXMenuType } from '@/utils/constant'

export function list(params) {
  return request({
    url: '/api/image/image_category/',
    method: 'get',
    params
  })
}

export async function add(data, menuType = 'btn') {
  // eslint-disable-next-line no-undef
  return refreshDic(
    request({
      headers: getXMenuType(menuType),
      url: '/externaladmin/productService/imageCategory/create',
      method: 'post',
      data
    }),
    'category'
  )
}

export function read(data) {
  return request({
    url: `/api/image/image_category/${data}/`,
    method: 'get',
    data
  })
}

export async function edit(data, menuType = 'btn') {
  // eslint-disable-next-line no-undef
  return refreshDic(
    request({
      headers: getXMenuType(menuType),
      url: '/externaladmin/productService/imageCategory/update',
      method: 'post',
      data
    }),
    'category'
  )
}

export async function del(data, menuType = 'btn') {
  // eslint-disable-next-line no-undef
  return refreshDic(
    request({
      headers: getXMenuType(menuType),
      url: '/externaladmin/productService/imageCategory/delete',
      method: 'post',
      data
    }),
    'category'
  )
}
